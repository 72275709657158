import React, { useState, useEffect } from 'react';
import { loadStripe, StripeElementsOptions, PaymentIntent } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { BASE_URL } from '../../Const/APIPath';
import { Button } from '@mui/material';

// Load Stripe with your publishable key (replace with your actual key)
const stripePromise = loadStripe("pk_live_51PtdGrCjqdAvBi1ucoDxHQtyHNRW5adiEeIZ4lYhUNMZAD06VlZ3YOpQoSNAybef6ptzwz5ZsSpifVEJTRwfUeru00TAWTcTgQ");

// Define props for customization and reusability
interface StripePaymentProps {
  amount: number; // Amount in cents, e.g., 1000 cents = $10
  currency?: string; // Currency type, default is 'usd'
  onSuccess?: (paymentIntentId: string, details: any) => void; // Callback on successful payment
  onError?: (errorMessage: string) => void; // Callback on payment error
}

const StripePaymentForm: React.FC<StripePaymentProps & { clientSecret: string }> = ({ amount, currency = 'eur', onSuccess, onError,clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [paymentRequest, setPaymentRequest] = useState<any>(null); // Add state for PaymentRequest

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Create a payment request
    const pr = stripe.paymentRequest({
      country: 'DE', // Replace with your country code
      currency: currency,
      total: {
        label: 'Total',
        amount: amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
    });

    // Check if payment can be made with any of the supported methods
    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
      }
    });
  }, [stripe, currency, amount]);

  const handlePayment = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements || !clientSecret) {
      setErrorMessage('Stripe is not initialized or client secret is missing. Please try again.');
      return;
    }

    try {
      setIsProcessing(true);
      setErrorMessage(null); // Clear any existing errors

      // Confirm payment with Stripe
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href, // Optionally add a return URL for redirect payments
        },
      });

      if (result.error) {
        setErrorMessage(result.error.message || 'Payment failed.');
        onError?.(result.error.message || 'Payment failed.');
      } else if ('paymentIntent' in result && result.paymentIntent) {
        // Cast paymentIntent to the correct type
        const paymentIntent = result.paymentIntent as PaymentIntent;

        if (paymentIntent.status === 'succeeded') {
          onSuccess?.(paymentIntent.id, paymentIntent);
        } else {
          setErrorMessage('Payment failed for an unknown reason.');
          onError?.('Payment failed for an unknown reason.');
        }
      }
    } catch (error) {
      setErrorMessage((error as Error).message || 'An error occurred during payment.');
      onError?.((error as Error).message || 'An error occurred during payment.');
    } finally {
      setIsProcessing(false);
    }
  };

  if (!clientSecret) {
    return <div>Loading payment options...</div>; // Optionally show a loading state
  }

  return (
    <form onSubmit={handlePayment} style={{ maxWidth: 400 }}>
      {paymentRequest && (
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                theme: 'dark', // Can be 'dark', 'light', 'light-outline'
                height: '40px',
                type: 'buy', // 'buy' or 'donate' or 'default'
              },
            },
          }}
        />
      )}
      <PaymentElement
        options={{
          layout: 'tabs', // Options: 'tabs', 'accordion', 'auto'
          paymentMethodOrder:['card','paypal', 'apple_pay', 'google_pay'],

        }}

      />
      {errorMessage && <div style={{ color: 'red', marginTop: '0.5rem' }}>{errorMessage}</div>}
      <Button type="submit" variant='outlined' disabled={!stripe || isProcessing} style={{ marginTop: '1rem' }}>
        {isProcessing ? 'Processing...' : 'Pay Now'}
      </Button>
    </form>
  );
};

const StripePayment: React.FC<StripePaymentProps> = (props) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isNaN(props.amount) || props.amount <= 0) {
      setErrorMessage('Invalid payment amount.');
      return;
    }

    const fetchClientSecret = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}course/createPaymentIntent`,
          { amount: props.amount, currency: props.currency },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const secret = response.data.data.clientSecret;
        setClientSecret(secret);
      } catch (error) {
        setErrorMessage('Failed to retrieve client secret from server.');
      }
    };

    fetchClientSecret();
  }, [props.amount, props.currency]);

  if (!clientSecret) {
    return <div>Loading payment options...</div>; // Show a loading state
  }

  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: 'stripe',
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripePaymentForm {...props} clientSecret={clientSecret} />
    </Elements>
  );
};

export default StripePayment;