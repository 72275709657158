import React, { ChangeEvent, Dispatch, useEffect, useMemo, useRef, useState } from 'react';
import { courseData } from '../../../Const/StateVariable';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Button, Chip, Divider, Grid } from '@mui/material';
import './CreateCourse.css'; // Import custom CSS for styling
import { CustomMessage, Loader } from '../../../Const/Spinner';
import { enqueueSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Redux/store/ReduxStore';
import { setLoading } from '../../../Redux/CommonSlice';
import { courseContentUpload, courseCreation, courseUpdation, courseVideoContentUpload, deleteCourseCoverFile, getCourseById, getListOfCourse } from '../../../Const';
import { decodeBase64 } from '../../../Const/const';
import { Cancel, Delete, DeleteOutline, Download, Language } from '@mui/icons-material';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ITooltipParams } from 'ag-grid-community';
import { CourseContent, CourseDefault, courseList } from '../../../type';
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from '@mui/icons-material/Add';
import { STREAM_IMAGE_GET } from '../../../Const/APIPath';

interface CreateCourseProps {
    setShow: Dispatch<React.SetStateAction<boolean>>;
}
const CreateCourse: React.FC<CreateCourseProps> = ({setShow}) => {
    const dispatch = useDispatch();
    const userId = useSelector((state:RootState)=>state.auth.userId);
    const loading = useSelector((state:RootState)=>state.common.data.loading);

    const validImageFileTypes = ["image", "png", "jpg", "jpeg", "gif", "svg", "webp"];
    const [courseList,setCourseList] = useState<courseList[]>([]);
    const [courseDetails, setCourseDetails] = useState(courseData);
    const [showlist,setShowList] = useState(true);
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridApiRef = useRef<any>(null);

    const toolTipValueGetter = (params: ITooltipParams) =>params.value == null || params.value === '' ? '- Missing -' : params.value;
    const columnDefs: ColDef[] = [
        {
            headerName:"Id",
            field:"courseId",
            filter: "agSetColumnFilter",
            sortable: true,
            editable:false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "courseId",
            flex:1,
        },
        {
            headerName:"Course Name",
            field:"courseName",
            filter: "agSetColumnFilter",
            sortable: true,
            editable:false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "courseName",
            flex:1,
        },
        {
            headerName:"Count of Content",
            field:"contentCount",
            filter: "agSetColumnFilter",
            sortable: true,
            editable:false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "contentCount",
            flex:1,
        },
        {
            headerName:"Actions",
            field:"actions",
            filter: "agSetColumnFilter",
            sortable: false,
            editable:false,
            cellRenderer:(params:any)=>(
                <div>
                    <span
                    style={{ cursor: 'pointer', color: 'rgba(255, 138, 0, 1)' }}
                    onClick={() => handleGetCourse(params?.data?.courseId)}
                    >
                        <EditIcon />
                    </span>
                </div>
            )
        },
    ];

    const handleGetCourse = async (courseId:number) =>{
        dispatch(setLoading(true));
        try{
            const response =await getCourseById(courseId);
            if(response.status.code ===200){
                setCourseDetails(response.data);
                setShowList(false);
            }else{
                CustomMessage(response.status.message,'error',enqueueSnackbar);
            }
        }catch(error:any){
            CustomMessage(error?.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }
    }
    useEffect(()=>{
        if(courseList.length === 0){
            getCourseList();
        }
    },[courseList])

    const getCourseList = async() => {
        dispatch(setLoading(true));
        try{
            const response = await getListOfCourse(userId);
            const {data,status} = response;
            if(status.code === 200 && data?.length>0){
                setCourseList(data);
            }else{
                CustomMessage(status?.message,'error',enqueueSnackbar);
            }
        }catch(error:any){
            CustomMessage(error?.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }
    }

    const addContentField = (language: 'english' | 'german') => {
        setCourseDetails(prevDetails => ({
        ...prevDetails,
        content: {
            ...prevDetails.content,
            [language]: [
                ...prevDetails.content[language],
                {
                    contentId: 0,
                    contentName: '',
                    description: '',
                    contentFileId: 0,
                    filePath: '',
                    fileType: '',
                    posterPictureId: 0,
                    posterFilePath: ''
                }
            ]
        }
        }));
    };

    const removeContentField = (index: number, language: "english" | "german") => {
        const updatedCourseDetails = { ...courseDetails };
        const updatedContent = [...updatedCourseDetails.content[language]];
        updatedContent.splice(index, 1);
        updatedCourseDetails.content[language] = updatedContent;
        setCourseDetails(updatedCourseDetails);
    };


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const updatedCourseDetails = { ...courseDetails, [name]: value };
        setCourseDetails(updatedCourseDetails);
    }
    const handleContentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number,language:"english" | "german") => {
        const { name, value } = e.target;
        const updatedCourseDetails = { ...courseDetails };
        const updatedCourseContent = [...updatedCourseDetails.content[language]];
        updatedCourseContent[index][name] = value;
        updatedCourseDetails.content[language] = updatedCourseContent;
        setCourseDetails(updatedCourseDetails);
    }
     const validateContentIds = (contents: CourseContent[]) => {
        const ids = contents.map(content => content.contentId);
        const uniqueIds = new Set(ids);
        return ids.every(id => id > 0) && uniqueIds.size === ids.length;
    };
    const handleSubmit = async (e:any) => {
        e?.preventDefault();
        if (!validateContentIds(courseDetails.content["english"])) {
            CustomMessage('Each English content ID must be unique and greater than 0','error',enqueueSnackbar);
            return;
        }

        if (!validateContentIds(courseDetails.content["german"])) {
            CustomMessage('Each German content ID must be unique and greater than 0','error',enqueueSnackbar);
            return;
        }

        if(courseDetails.content["english"]?.length === 0){
            CustomMessage("Add the English course content",'error',enqueueSnackbar);
            return;
        }

        if(courseDetails.content["german"]?.length === 0){
            CustomMessage("Add the german course content",'error',enqueueSnackbar);
            return;
        }

        const updatedcourseDetails = {...courseDetails};
        if (updatedcourseDetails.content["english"].some(val => val.contentFileId === 0)) {
            CustomMessage("Add the English content files",'error',enqueueSnackbar);
            return;
        }
        if (updatedcourseDetails.content["english"].some(val => val.posterPictureId === 0)) {
            CustomMessage("Add the English video Poster files",'error',enqueueSnackbar);
            return;
        }

        if (updatedcourseDetails.content["german"].some(val => val.contentFileId === 0)) {
            CustomMessage("Add the German content files",'error',enqueueSnackbar);
            return;
        }
        if (updatedcourseDetails.content["german"].some(val => val.posterPictureId === 0)) {
            CustomMessage("Add the German video Poster files",'error',enqueueSnackbar);
            return;
        }

        dispatch(setLoading(true));
        
        try{
            if(updatedcourseDetails.courseId === 0){
                updatedcourseDetails.createdBy = userId;
                const response = await courseCreation(updatedcourseDetails);
                let { data, status } = response;
                if(status.code === 200){
                    handleClear();
                    CustomMessage(status.message,'success',enqueueSnackbar);
                    getCourseList();
                }else{
                    CustomMessage(status.message,'error',enqueueSnackbar);
                }
            }else{
                updatedcourseDetails.updatedBy = userId;
                const response = await courseUpdation(updatedcourseDetails);
                let { data, status } = response;
                if(status.code === 200){
                    handleClear();
                    CustomMessage(status.message,'success',enqueueSnackbar);
                    getCourseList();
                }else{
                    CustomMessage(status.message,'error',enqueueSnackbar);
                }
            }
        }catch(error:any){
            CustomMessage(error?.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }
    }
    const hanldleFileChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>{
        const fileList = (e.target as HTMLInputElement).files;
        if (fileList && fileList.length > 0) {
            const payloadData = new FormData();
                payloadData.append('filePath',fileList[0]);
                payloadData.append('createdBy',userId?.toString());
                dispatch(setLoading(true));
            try{
                const response = await courseContentUpload(payloadData,"course");
                const { data, status } = response;
                if(status.code === 200){
                    const updatedCourseDetails = {...courseDetails};
                        updatedCourseDetails.courseCoverPictureId = data?.id;
                        updatedCourseDetails.coverFilePath = data?.filePath;
                        updatedCourseDetails.fileType = data?.fileType;
                    setCourseDetails(updatedCourseDetails);
                    CustomMessage(status.message,'success',enqueueSnackbar);
                }else{
                    CustomMessage(status.message,'error',enqueueSnackbar);
                }
            }catch(error:any){
                CustomMessage(error?.message,'error',enqueueSnackbar);
            }finally{
                dispatch(setLoading(false));
            }
        }else{
            CustomMessage("No file selected",'error',enqueueSnackbar);
        }
    }
    const hanldleContentPosterFileChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index:number,language:"english" | "german") => {
        const fileList = (e.target as HTMLInputElement).files;
        if (fileList && fileList.length > 0) {
            const payloadData = new FormData();
                payloadData.append('filePath',fileList[0]);
                payloadData.append('createdBy',userId?.toString());
            dispatch(setLoading(true));
            try{
                const response = await courseContentUpload(payloadData,"poster");
                const { data, status } = response;
                if(status.code === 200){
                    const updatedCourseDetails = {...courseDetails};
                        const updatedContent = [...updatedCourseDetails.content[language]];
                            updatedContent[index].posterPictureId = data?.id;
                            updatedContent[index].posterFilePath = data?.filePath;
                        updatedCourseDetails.content[language] = updatedContent;
                    setCourseDetails(updatedCourseDetails);
                    CustomMessage(status.message,'success',enqueueSnackbar);
                }else{
                    CustomMessage(status.message,'error',enqueueSnackbar);
                }
            }catch(error:any){
                CustomMessage(error?.message,'error',enqueueSnackbar);
            }finally{
                dispatch(setLoading(false));
            }
        }else{
            CustomMessage("No file selected",'error',enqueueSnackbar);
        }
    }
    // const hanldleContentFileChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index:number,type:string,language:"english" | "german") => {
    //     const inputElement = e.target as HTMLInputElement;
    //     if (inputElement.files && inputElement.files.length > 0) {
    //         const file = inputElement.files[0]; // Get the first file from the input
    //         const payloadData = new FormData();
    //             payloadData.append('filePath',file);
    //             payloadData.append('createdBy',userId?.toString());
    //         dispatch(setLoading(true));
    //         try{
    //             const response = await courseContentUpload(payloadData,type);
    //             const { data, status } = response;
    //             if(status.code === 200){
    //                 const updatedCourseDetails = {...courseDetails};
    //                     const updatedContent = [...updatedCourseDetails.content[language]];
    //                         updatedContent[index].contentFileId = data?.id;
    //                         updatedContent[index].filePath = data?.filePath;
    //                         updatedContent[index].fileType = data?.fileType;
    //                     updatedCourseDetails.content[language] = updatedContent;
    //                 setCourseDetails(updatedCourseDetails);
    //                 CustomMessage(status.message,'success',enqueueSnackbar);
    //             }else{
    //                 CustomMessage(status.message,'error',enqueueSnackbar);
    //             }
    //         }catch(error:any){
    //             CustomMessage(error?.message,'error',enqueueSnackbar);
    //         }finally{
    //             dispatch(setLoading(false));
    //         }
    //     }else{
    //         CustomMessage("No file selected",'error',enqueueSnackbar);
    //     }
    // }

    // const handleContentFileChange = async (e: React.ChangeEvent<HTMLInputElement>, index: number, type: string, language: "english" | "german") => {
    //     const inputElement = e.target as HTMLInputElement;
    //     if (inputElement.files && inputElement.files.length > 0) {
    //         const file = inputElement.files[0];
    //         const chunkSize = 1024 * 1024 * 5; // 5MB
    //         const totalChunks = Math.ceil(file.size / chunkSize);
    //         const fileName = file.name;
    //         const createdBy = userId?.toString();

    //         console.log(fileName,'fileName');

    //         dispatch(setLoading(true));

    //         for (let i = 0; i < totalChunks; i++) {
    //             const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
    //             const payloadData = new FormData();
    //             payloadData.append('filePath', chunk);
    //             payloadData.append('fileName', fileName);
    //             payloadData.append('chunkIndex', i.toString());
    //             payloadData.append('totalChunks', totalChunks.toString());
    //             payloadData.append('createdBy', createdBy);

    //             try {
    //                 const response = await courseVideoContentUpload(payloadData, type);
    //                 const { status } = response;

    //                 if (status.code !== 200) {
    //                     CustomMessage(status.message, 'error', enqueueSnackbar);
    //                     break;
    //                 } else if (i === totalChunks - 1) {
    //                     const { data } = response;
    //                     const updatedCourseDetails = { ...courseDetails };
    //                     const updatedContent = [...updatedCourseDetails.content[language]];
    //                     updatedContent[index].contentFileId = data?.id;
    //                     updatedContent[index].filePath = data?.filePath;
    //                     updatedContent[index].fileType = data?.fileType;
    //                     updatedCourseDetails.content[language] = updatedContent;
    //                     setCourseDetails(updatedCourseDetails);
    //                     CustomMessage(status.message, 'success', enqueueSnackbar);
    //                 }
    //             } catch (error: any) {
    //                 CustomMessage(error.message, 'error', enqueueSnackbar);
    //                 break;
    //             }
    //         }

    //         dispatch(setLoading(false));
    //     } else {
    //         CustomMessage("No file selected", 'error', enqueueSnackbar);
    //     }
    // };

    const handleContentFileChange = async (e: React.ChangeEvent<HTMLInputElement>, index: number, type: string, language: "english" | "german") => {
    const inputElement = e.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
        const file = inputElement.files[0];
        const chunkSize = 1024 * 1024 * 5; // 5MB
        const totalChunks = Math.ceil(file.size / chunkSize);
        const fileName = file.name;
        const createdBy = userId?.toString();
        let uniqueFileName = '';

        dispatch(setLoading(true));

        for (let i = 0; i < totalChunks; i++) {
            const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
            const payloadData = new FormData();
            payloadData.append('filePath', chunk);
            payloadData.append('fileName', fileName);
            payloadData.append('chunkIndex', i.toString());
            payloadData.append('totalChunks', totalChunks.toString());
            payloadData.append('createdBy', createdBy);

            // Only append uniqueFileName if it has been set
            if (uniqueFileName) {
                payloadData.append('uniqueFileName', uniqueFileName);
            }

            try {
                const response = await courseVideoContentUpload(payloadData, type);
                const { status, data } = response;

                if (status.code !== 200) {
                    CustomMessage(status.message, 'error', enqueueSnackbar);
                    break;
                } else if (i === 0) {
                    uniqueFileName = data.uniqueFileName; // Capture uniqueFileName from first response
                } else if (i === totalChunks - 1) {
                    const updatedCourseDetails = { ...courseDetails };
                    const updatedContent = [...updatedCourseDetails.content[language]];
                    updatedContent[index].contentFileId = data?.id;
                    updatedContent[index].filePath = data?.filePath;
                    updatedContent[index].fileType = data?.fileType;
                    updatedCourseDetails.content[language] = updatedContent;
                    setCourseDetails(updatedCourseDetails);
                    CustomMessage(status.message, 'success', enqueueSnackbar);
                }
            } catch (error: any) {
                CustomMessage(error.message, 'error', enqueueSnackbar);
                break;
            }
        }

        dispatch(setLoading(false));
    } else {
        CustomMessage("No file selected", 'error', enqueueSnackbar);
    }
};


    const removeCourseCoverFile = async (id:number) => {
        dispatch(setLoading(true));
        try{
            const response = await deleteCourseCoverFile(id);
            let { data, status } = response;
            if(status.code === 200){
                const updatedCourse = {...courseDetails};
                    updatedCourse.filePath = "";
                    updatedCourse.fileType = "";
                    updatedCourse.courseCoverPictureId = 0;
                    setCourseDetails(updatedCourse);
                CustomMessage(status.message,'success',enqueueSnackbar);
            }else{
                CustomMessage(status.message,'error',enqueueSnackbar);
            }            
        }catch(error:any){
            CustomMessage(error?.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }
    }

    const removeCoursePosterContentFile = async (id:number,index:number,language:"english" | "german") => {
        dispatch(setLoading(true));
        try{
            const response = await deleteCourseCoverFile(id);
            let { data, status } = response;
            if(status.code === 200){
                const updatedCourse = {...courseDetails};
                    const updatedContent = [...updatedCourse.content[language]];
                        updatedContent[index].posterFilePath = "";
                        updatedContent[index].posterPictureId = 0;
                    updatedCourse.content[language] = updatedContent;
                setCourseDetails(updatedCourse);
                CustomMessage(status.message,'success',enqueueSnackbar);
            }else{
                CustomMessage(status.message,'error',enqueueSnackbar);
            }            
        }catch(error:any){
            CustomMessage(error?.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }
    }

    const removeContentFile = async (id:number,index:number,language:"english" | "german") => {
        dispatch(setLoading(true));
        try{
            const response = await deleteCourseCoverFile(id);
            let { data, status } = response;
            if(status.code === 200){
                const updatedCourse = {...courseDetails};
                    const updatedContent = [...updatedCourse.content[language]];
                        updatedContent[index].filePath = "";
                        updatedContent[index].fileType = "";
                        updatedContent[index].contentFileId = 0;
                    updatedCourse.content[language] = updatedContent;
                setCourseDetails(updatedCourse);
                CustomMessage(status.message,'success',enqueueSnackbar);
            }else{
                CustomMessage(status.message,'error',enqueueSnackbar);
            }            
        }catch(error:any){
            CustomMessage(error?.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        if (gridApiRef.current) {
            gridApiRef.current.api.sizeColumnsToFit();
        }
    }, []);
    const defaultColDef = useMemo<ColDef>(() => {
        return {
            minWidth: 200,
            filter: true,
        };
    }, []);
    const getRowId = useMemo(() => {
        return (params : any) => params?.data?.courseId;
    }, []);

    const handleClear = () => {
        setShowList(true);
        setCourseDetails({
            content:{
                english: [] as CourseContent[],
                german: [] as CourseContent[]
            },
            courseCoverPictureId:0,
            courseId:0,
            courseName:'',
            coverFilePath:'',
            createdBy:0,
            fileType:"",
            updatedBy:0
        })
    }

    return (
        <Container fluid>
            {
                loading && <Loader />
            }
            {
                showlist ?(
                    <Grid item xs={11}>
                        {/* <Chip variant='outlined' title="Add New" color='primary' onClick={()=>setShowList(false)} icon={<AddIcon />} label={"Add New"} /> */}
                        {/* <Button onClick={()=>setShowList(false)}>Add New</Button> */}
                        <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <div style={containerStyle}>
                                <div  style={gridStyle}>
                                    <AgGridReact
                                        className="ag-theme-alpine"
                                        defaultColDef={defaultColDef}
                                        rowData={courseList}
                                        columnDefs={columnDefs}
                                        onGridReady={(params) => (gridApiRef.current = params)}
                                        // defaultColDef={{ flex: 1, minWidth:100 }}
                                        domLayout="autoHeight"
                                        // editType="fullRow"
                                        // onCellValueChanged={handleCellValueChanged}
                                        pagination={true}
                                        paginationPageSize={25}
                                        paginationPageSizeSelector={[25,50,100]}
                                        alwaysShowHorizontalScroll={true}
                                        alwaysShowVerticalScroll={true}
                                        // cacheBlockSize={10}
                                        getRowId={getRowId}
                                        suppressMenuHide={true}
                                    />  
                                </div>
                            </div>
                        </div>
                    </Grid>
                )
                :
                (
                    <Form onSubmit={handleSubmit}>
                        <Divider variant='fullWidth' />
                        <Col><h5>Course Main</h5></Col>
                        <div className='content-field'>
                            <Row md={5} className="justify-content-center mb-4">
                                <Col md={5}>
                                    <Form.Group controlId="courseName">
                                        <Form.Label>Course Name</Form.Label>
                                        <Form.Control type="text" name="courseName" required value={courseDetails.courseName} onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row md={5} className="justify-content-center mb-4">
                                <Col md={5}>
                                    {
                                        courseDetails.courseCoverPictureId === 0 && (
                                            <Form.Group controlId="courseCover">
                                                <Form.Label>Upload Course Cover</Form.Label>
                                                <Form.Control type="file" onChange={(e)=>hanldleFileChange(e)} accept="image/*" />
                                            </Form.Group>
                                        )
                                    }
                                    { 
                                        courseDetails.courseCoverPictureId != 0 &&(
                                            <div style={{display:'flex', justifyContent:"space-between"}}>
                                                <img 
                                                    src={STREAM_IMAGE_GET + courseDetails?.courseCoverPictureId}
                                                    // src={decodeBase64(courseDetails.coverFilePath)}
                                                    alt='Course cover image' style={{height:'250px',width:'500px',objectFit:'contain'}}/>
                                                <div onClick={()=>removeCourseCoverFile(courseDetails.courseCoverPictureId)} style={{color:'red', cursor:'pointer'}} className='mt-5'>
                                                    <Cancel titleAccess='Remove'/>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Col>
                            </Row>
                        </div>
                        <Divider textAlign="center"
                            sx={{
                                "&::before, &::after": {
                                    borderColor: "primary.main",
                                },
                            }}
                            variant='middle'
                        >
                            <Chip label="English Video" color="info" variant="outlined"/>
                        </Divider>
                            <h5>Course Content 
                                <span><Chip variant='outlined' title="Add Content" color='primary' label="Add Content" onClick={()=>addContentField("english")} icon={<AddIcon titleAccess='Add content'/>}/></span>
                            </h5>
                        {courseDetails?.content?.english.map((content, index) => (
                            <div key={index} className="content-field">
                                <Row md={12} className='d-flex justify-content-between'>
                                    <Col md={3}>
                                        <Form.Group controlId={`contentId-${index}`}>
                                            <Form.Label>Order flow</Form.Label>
                                            <Form.Control type='number' name='contentId' aria-required={true} onChange={(e)=>handleContentChange(e,index,'english')} required value={content.contentId}/>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} className="mb-2">
                                        <Form.Group controlId={`contentName-${index}`}>
                                            <Form.Label>Content Name</Form.Label>
                                            <Form.Control type="text" name="contentName" required value={content.contentName} onChange={(e) =>handleContentChange(e,index,'english')} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        {
                                            content.contentFileId === 0 && (
                                                <Form.Group controlId={`file-${index}`}>
                                                    <Form.Label>Upload Content File</Form.Label>
                                                    <Form.Control type="file" onChange={(e: React.ChangeEvent<HTMLInputElement>)=>handleContentFileChange(e,index,"video",'english')} accept="video/*" 
                                                        // accept="application/pdf, audio/*, video/*"
                                                    />
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            (!validImageFileTypes.includes(content.fileType)  && content.contentFileId !== 0) &&(
                                                <div style={{display:'flex', justifyContent:"space-between"}}>
                                                    <div onClick={()=>{}} style={{color:"blue", textDecoration:'underline',cursor:'pointer'}}>
                                                        {content.filePath}<Download titleAccess='Download'/>
                                                    </div>
                                                    <div onClick={()=>removeContentFile(content.contentFileId,index,"english")} style={{color:'red', cursor:'pointer'}} className='mt-5'>
                                                    <Cancel titleAccess='Remove'/>
                                                </div>
                                                </div>
                                            )
                                        }
                                    </Col>
                                    <Col md={3}>
                                        {
                                            content.posterPictureId === 0 && (
                                                <Form.Group controlId={`file-${index}`}>
                                                    <Form.Label>Upload Poster File</Form.Label>
                                                    <Form.Control type="file" onChange={(e)=>hanldleContentPosterFileChange(e,index, 'english')} accept="image/*" />
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            content.posterPictureId != 0 && (
                                                <div style={{display:'flex', justifyContent:"space-between"}}>
                                                    <img 
                                                        src={STREAM_IMAGE_GET + content?.posterPictureId}
                                                        // src={decodeBase64(content.posterFilePath)} 
                                                        alt='Course cover image' style={{height:'250px',width:'500px',objectFit:'contain'}}/>
                                                    <div onClick={()=>removeCoursePosterContentFile(content.posterPictureId,index,'english')} style={{color:'red', cursor:'pointer'}} className='mt-5'>
                                                        <Cancel titleAccess='Remove'/>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <Row md={12}>
                                            <Col md={12} className="mb-2">
                                                <Form.Group controlId={`description-${index}`}>
                                                    <Form.Label>Descriptions</Form.Label>
                                                    <Form.Control as="textarea" rows={6} height={4} name="description" value={content.description} onChange={(e) => handleContentChange(e,index,'english')} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={1} className="">
                                        <Chip className='mt-5' variant='outlined' onClick={() => removeContentField(index,"english")} title='Remove' color='error' label="Remove" icon={<DeleteOutline titleAccess='Remove' />}  />
                                        {/* <Button className='mt-5' variant="danger" onClick={() => removeContentField(index)}>Remove</Button> */}
                                    </Col>
                                </Row>
                                <Divider variant='fullWidth' />
                            </div>
                        ))}                        
                        <Divider textAlign="center"
                            sx={{
                                "&::before, &::after": {
                                    borderColor: "primary.main",
                                },
                            }}
                            variant='middle'
                        >
                            <Chip label="German Video" color="info" variant="outlined"/>
                        </Divider>
                        <h5>Course Content 
                                <span><Chip variant='outlined' title="Add Content" color='primary' label="Add Content" onClick={()=>addContentField("german")} icon={<AddIcon titleAccess='Add content'/>}/></span>
                            </h5>
                        {courseDetails?.content?.german.map((content, index) => (
                            <div key={index} className="content-field">
                                <Row md={12} className='d-flex justify-content-between'>
                                    <Col md={3}>
                                        <Form.Group controlId={`contentId-${index}`}>
                                            <Form.Label>Order flow</Form.Label>
                                            <Form.Control type='number' name='contentId' aria-required={true} onChange={(e)=>handleContentChange(e,index,'german')} required value={content.contentId}/>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} className="mb-2">
                                        <Form.Group controlId={`contentName-${index}`}>
                                            <Form.Label>Content Name</Form.Label>
                                            <Form.Control type="text" name="contentName" required value={content.contentName} onChange={(e) =>handleContentChange(e,index,'german')} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        {
                                            content.contentFileId === 0 && (
                                                <Form.Group controlId={`file-${index}`}>
                                                    <Form.Label>Upload Content File</Form.Label>
                                                    <Form.Control type="file" onChange={(e: React.ChangeEvent<HTMLInputElement>)=>handleContentFileChange(e,index,"video",'german')} accept="video/*" 
                                                        // accept="application/pdf, audio/*, video/*"
                                                    />
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            (!validImageFileTypes.includes(content.fileType)  && content.contentFileId !== 0) &&(
                                                <div style={{display:'flex', justifyContent:"space-between"}}>
                                                    <div onClick={()=>{}} style={{color:"blue", textDecoration:'underline',cursor:'pointer'}}>
                                                        {content.filePath}<Download titleAccess='Download'/>
                                                    </div>
                                                    <div onClick={()=>removeContentFile(content.contentFileId,index,"german")} style={{color:'red', cursor:'pointer'}} className='mt-5'>
                                                    <Cancel titleAccess='Remove'/>
                                                </div>
                                                </div>
                                            )
                                        }
                                    </Col>
                                    <Col md={3}>
                                        {
                                            content.posterPictureId === 0 && (
                                                <Form.Group controlId={`file-${index}`}>
                                                    <Form.Label>Upload Poster File</Form.Label>
                                                    <Form.Control type="file" onChange={(e)=>hanldleContentPosterFileChange(e,index, 'german')} accept="image/*" />
                                                </Form.Group>
                                            )
                                        }
                                        {
                                            content.posterPictureId != 0 && (
                                                <div style={{display:'flex', justifyContent:"space-between"}}>
                                                    <img 
                                                        src={STREAM_IMAGE_GET + content.posterPictureId}
                                                        // src={decodeBase64(content.posterFilePath)}
                                                        alt='Course cover image' style={{height:'250px',width:'500px',objectFit:'contain'}}/>
                                                    <div onClick={()=>removeCoursePosterContentFile(content.posterPictureId,index,'german')} style={{color:'red', cursor:'pointer'}} className='mt-5'>
                                                        <Cancel titleAccess='Remove'/>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <Row md={12}>
                                            <Col md={12} className="mb-2">
                                                <Form.Group controlId={`description-${index}`}>
                                                    <Form.Label>Descriptions</Form.Label>
                                                    <Form.Control as="textarea" rows={6} height={4} name="description" value={content.description} onChange={(e) => handleContentChange(e,index,'german')} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={1} className="">
                                        <Chip className='mt-5' variant='outlined' onClick={() => removeContentField(index,"german")} title='Remove' color='error' label="Remove" icon={<DeleteOutline titleAccess='Remove' />}  />
                                        {/* <Button className='mt-5' variant="danger" onClick={() => removeContentField(index)}>Remove</Button> */}
                                    </Col>
                                </Row>
                                <Divider variant='fullWidth' />
                            </div>
                        ))}  
                        <Divider variant='fullWidth' />
                        <Row className='d-flex justify-content-center align-items-center'>
                            <Col>
                                <Button variant="contained" color='primary' type='submit'>Submit</Button>
                            </Col>
                            <Col>
                                <Button variant='contained' color='secondary' type='reset' onClick={handleClear}>Cancel</Button>
                            </Col>
                        </Row>
                    </Form>
                )
            }
        </Container>
    )
}
export default CreateCourse;